import React from "react";

const Featurette = () => {
    return (
        <div className="row g-5">
            <div className="col-md-8">
                <div className="featurette-container">
                    <div className="row featurette">
                        <div className="col-md-7">
                            <h2 className="featurette-heading fw-normal lh-1">Full service diagnostic and repair</h2>
                            <p className="lead">Our certified technicians have over a decade of experience diagnosing and repairing all gas, diesel, hybrid and nonproprietary EV's.
                                Repair services include, steering and suspension, alignment, transmission, air conditioning, drive line, engine, electrical, hybrid, EV batteries, electric motors and emission repairs </p>
                        </div>
                        <div className="col-md-5">
                            <svg className="featurette-image-1 img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"></svg>
                        </div>
                    </div>
                    <div className="row featurette">
                        <div className="col-md-7 order-md-2">
                            <h2 className="featurette-heading fw-normal lh-1">State of the art vehicle alignment</h2>
                            <p className="lead">We use one of Hoffmans top of the line wheel alignment racks with superior accuracy to keep you car running straight! </p>
                        </div>
                        <div className="col-md-5 order-md-1">
                            <svg className="featurette-image-2 img-fluid mx-auto" width="300" height="400" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"></svg>
                        </div>
                    </div>
                    <div className="row featurette">
                        <div className="col-md-7">
                            <h2 className="featurette-heading fw-normal lh-1">Full Lube Service</h2>
                            <p className="lead">We also offer lube service to include, oil and filter, transmission fluid, power steering fluid, brake fluid, coolant/antifreeze, fuel induction and a/c service</p>
                        </div>
                        <div className="col-md-5">
                            <svg className="featurette-image-3 img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"></svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4" id="contact">
                <div className="position-sticky">
                    <div className="p-4 mb-3  rounded">
                        <svg className="logo-img" width="180" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" alt='shop logo' focusable="false"></svg>
                        <h4 className="socials">Call Us Today</h4>
                        <ol className="list-unstyled">
                            <li><a href="tel:+17194950123">(719) 495-0123</a></li>
                            <li>6580 Shoup Rd, Colorado Springs, CO 80908</li>
                        </ol>
                    </div>

                    <div className="p-4">
                        <h4 className="hours">Hours of operation</h4>
                        <ol className="list-unstyled mb-0">
                            <li>Sunday - CLOSED</li>
                            <li>Monday - 8 AM-5:30PM</li>
                            <li>Tuesday - 8 AM-5:30PM</li>
                            <li>Wednesday - 8 AM-5:30PM</li>
                            <li>Thursday - 8 AM-5:30PM</li>
                            <li>Friday - 8 AM-5:30PM</li>
                            <li>Saturday - CLOSED</li>
                        </ol>
                    </div>

                    <div className="p-4" data-bs-target="#contact">
                        <h4 className="socials">Connect With Us</h4>
                        <ol className="list-unstyled">
                            <li><a href="https://www.facebook.com/BlackForestAutomotiveInc/">Facebook</a></li>
                        </ol>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default Featurette;