import './App.css';
import NavBar from './components/NavBar';
import Carousel from './components/Carousel'
import Featurette from './components/Featurette';
import About from './components/About';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Carousel />
      <Featurette />
      <About />
      <Footer />
    </div>
  );
}

export default App;
