import React from "react"
const Carousel = () => {
    return (
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" className="active" aria-label="Slide 1" aria-current="true"></button>
                <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2" className=""></button>
                <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3" className=""></button>
            </div>
            <div className="carousel-inner" id="home">
                <div className="carousel-item carousel-image bg-img-1 active">
                    <div className="container">
                        <div className="carousel-caption text-start">
                            <svg className="testimonial-img-1" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" alt='5 star review' focusable="false"></svg>
                            <h4>"Friendly / good customer service, excellent work ethic, solid technicians, and reasonable pricing."</h4>
                            <h5 className="fw-normal">- Alan</h5>
                        </div>
                    </div>
                </div>
                <div className="carousel-item carousel-image bg-img-2">
                    <div className="container">
                        <div className="carousel-caption text-center">
                            <svg className="testimonial-img-1" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" alt='5 star review' focusable="false"></svg>
                            <h4>"This is a great shop! They are convenient, honest, and priced fair! We have been going for years and they always treat us right!"</h4>
                            <h5 className="fw-normal">- Jason</h5>
                        </div>
                    </div>
                </div>
                <div className="carousel-item carousel-image bg-img-3">
                    <div className="container">
                        <div className="carousel-caption text-end">
                            <svg className="testimonial-img-1" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" alt='5 star review' focusable="false"></svg>
                            <h4>"Always knowledgeable, friendly staff. They have honest and fair prices!"</h4>
                            <h5 className="fw-normal">- Cassandra</h5>
                        </div>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}
export default Carousel