import React from "react";

const About = () => {
    return (
        <div className="about-us" id="about">
            <p className="about-us-btn">
                <a className="btn btn-outline-secondary" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                    About Us
                </a>
            </p>
            <div className="collapse" id="collapseExample">
                <div className="card card-body" id="about">
                    Black Forest Automotive has been a staple to the community since 1953. We strive to service our members of the community with the best possible care. Eric Aeschlliman, owner and CEO of Black Forest Automotive, has been here since 2004. He originally started as a service manager working for the previous owner. On May 15th of 2012, Eric and Kim, his wife, purchased Black Forest Automotive with the help of the community. Since the purchase, Eric and his wife have been working hard on repairing the building and streamlining the business to better service our community members. Over the last decade they have built an elite team to repair vehicles and provide the absolute best customer service to our customers. We do our very best to provide quality work along with getting the very best parts available for each vehicle. We will continue to strive for excellence and serve our community to the best of our ability.
                    <svg className="about-us-img img-fluid mx-auto" width="400" height="300" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 400x400" focusable="false"></svg>
                </div>
            </div>
            <svg className="logo-img" width="180" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" alt='shop logo' focusable="false"></svg>

        </div>
    )
};
export default About;