import React from "react";

const Footer = () => {
    return (
        <div className="footer-container">
            <footer className="d-flex flex-wrap align-items-center py-3 border-top">
                <div className="col-md-4 d-flex align-items-center">
                    <span className="mb-3 mb-md-0">© 2023 BlackForestAutomotive, Inc</span>
                </div>
                <span className="mb-3 mb-md-0" id="linkedin" >Website By <a href="https://www.linkedin.com/in/braden-chapman-b25a05234/" id="linkedin">Braden Chapman</a></span>
            </footer>
        </div>
    )
}
export default Footer;