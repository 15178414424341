import React from "react"
const NavBar = () => {
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary sticky-top" data-bs-theme="dark">
            <div className="container">
                <svg className="logo-img" width="50" height="50" xmlns="http://www.w3.org/2000/svg" role="img" preserveAspectRatio="xMidYMid slice" alt='logo' focusable="false"></svg>
                <a className="navbar-brand" href="#home">Blackforest Auto</a>
                <div className="navbar-expand" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link  active" aria-current="page" href="#myCarousel">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#about">About</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#hours" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Hours
                            </a>
                            <ul className="dropdown-menu">
                                <li>Sunday - CLOSED</li>
                                <li>Monday - 8 AM-5:30PM</li>
                                <li>Tuesday - 8 AM-5:30PM</li>
                                <li>Wednesday - 8 AM-5:30PM</li>
                                <li>Thursday - 8 AM-5:30PM</li>
                                <li>Friday - 8 AM-5:30PM</li>
                                <li>Saturday - CLOSED</li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#contact">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
export default NavBar